import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import Routes from './routes';
import createStore from './store';
import './Common.scss';
import '@atfm/typeface';

const store = createStore();

const history = createBrowserHistory();
const root = document.getElementById('root');

navigator.serviceWorker.register('/service-worker.js').then((registration) => {
  registration.update();
});

ReactDOM.render(
  <Provider store={store} history={history}>
    <Router history={history}>
      {Routes()}
    </Router>
  </Provider>,
  root,
);
