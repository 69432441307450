export const Application = Object.freeze({
  APPLICATION_NAME: 'TopSky - Flow Manager',
  APP_ADMIN: 'Administration',
  APP_CDM: 'CDM',
  APP_POSTOPS: 'Replay',
  APP_PRETACT: 'Pre-Tactical',
  APP_REGULATIONS: 'Regulation',
  APP_SEQ: 'Sequencer',
  APP_GREEN: 'Post-Ops KPI',
  APP_STATUS: 'Status',
  APP_HELP: 'User Guide',
  APP_SWAGGER: 'Swagger',
  APP_TACTICAL: 'Tactical',
});

export const Environment = Object.freeze({
  DEV: 'dev',
  BETA: 'beta',
  STAGING: 'staging',
});

const getApplicationEnvironment = (url) => {
  const betaEnvironments = [Environment.DEV, Environment.BETA, Environment.STAGING];

  return betaEnvironments.some(env => url.includes(env)) ? 'beta.' : '';
};

let currentDomain;
switch (window.location.hostname) {
  case 'localhost':
  case window.env.DEFAULT_DOMAIN:
    currentDomain = window.env.DEFAULT_DOMAIN;
    break;

  default:
    currentDomain = window.location.hostname.split('.').slice(1).join('.');
    break;
}

export const ApplicationPaths = Object.freeze({
  APP_ADMIN: `https://admin.${currentDomain}/`,
  APP_CDM: `https://chat.${currentDomain}/`,
  APP_POSTOPS: `https://front.${currentDomain}/postops`,
  APP_PRETACT: `https://front.${currentDomain}/pretact`,
  APP_REGULATIONS: `https://regulations.${currentDomain}/`,
  APP_SEQ: `https://${getApplicationEnvironment(currentDomain)}seq.topsky.thalesdigital.io`,
  APP_GREEN_STAG: 'https://postops-front.staging.green-operations.ams.thalesdigital.io/',
  APP_GREEN_PROD: 'https://postops-front.green-operations.ams.thalesdigital.io/',
  APP_STATUS: `https://status.${currentDomain}/`,
  APP_SWAGGER: `https://api-docs.${currentDomain}/`,
  APP_TACTICAL: `https://front.${currentDomain}/`,
  APP_HELP: `https://docs.${currentDomain}/help/`,
});

export const ApplicationRoles = Object.freeze({
  APP_ADMIN: 'atfm_admin_page',
  APP_CDM: 'cdm_{env}_user_access_token',
  APP_POSTOPS: 'atfm_user',
  APP_PRETACT: 'atfm_user',
  APP_REGULATIONS: 'atfm_public_regulations',
  APP_SEQ: 'seq_access',
  APP_GREEN_AIR: 'greenops_airport_read',
  APP_GREEN_FIR: 'greenops_fir_read',
  APP_GREEN: 'greenops_fir_read',
  APP_SWAGGER: 'atfm_docs',
  APP_TACTICAL: 'atfm_user',
});

export const currentAddress = `${window.location.protocol}//${window.location.host}`;
const redirectToken = window.env.AUTH_REDIRECT_URI_TOKEN;
export const Authentication = Object.freeze({
  LOGIN_REDIRECT: window.env.OIDC_LOGIN_REDIRECT.replace(redirectToken, currentAddress),
});

export const Time = Object.freeze({
  MINUTE_IN_MILISECONDS: 60000,
  HOUR_IN_MILISECONDS: 3600000,
});

export const CdmPaths = Object.freeze({
  CDM_SSO: '/oauth/gitlab/login',
});

export const UserInfoPaths = Object.freeze({
  USERINFO: '/api/v1/userinfo',
});
